/**
 * @note webpackのアセットコンパイル用
 * @link https://techracho.bpsinc.jp/hachi8833/2020_01_16/85940
 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/**
 * @link https://techracho.bpsinc.jp/hachi8833/2020_01_17/85943
 */
require("jquery");
// require("bootstrap");
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// @link https://medium.com/@guilhermepejon/how-to-install-bootstrap-4-3-in-a-rails-6-app-using-webpack-9eae7a6e2832
import './bootstrap_custom.js'
import '../stylesheets/application.scss'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// stimulus
import "controllers"
// api call
import "api"

// sweetalert2
import Swal from 'sweetalert2';
window.Swal = Swal;

$(document).on('turbolinks:load', function() {
  // bootstrap tooltip
  $('#js-tooltip-user-name')
  // Tooltipの設定
  .tooltip({
    title: '<span>使用可能な文字</span><br>半角全角問わず10文字まで使用可能です。<br>全角文字、半角英数字、以下の記号（半角・全角）<br>！”＃＄％＆’（）＊＋，－．／：；＜＝＞？＠［￥］＾＿｀｛｜｝～',
    html: true,
    placement: 'bottom',
    trigger: 'manual',
    template: '<div class="tooltip-user-name tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
  })
  // クリック時の動作を設定
  .on('click', function() {
    if ($('.tooltip-inner').css('display') == 'block') {
      $(this).tooltip('hide');
    } else {
      $(this).tooltip('show');
    }
  });

  // IE Guideページへのリダイレクト
  if (!(location.pathname.match(/\/(lp|cl\/lp|ie_guide)/))) {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('msie') !== -1 || (userAgent.indexOf('trident') !== -1)) {
      location.href = '/ie_guide';
    }
  }

  // スクロール位置設定
  var urlHash = location.hash;
  if(!urlHash){
    window.scrollTo(0, 0)
  } else {
    const setAnchorLink = function () {
      const targetElement = document.getElementById(urlHash.substr(1));
      const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      $("html, body").animate({scrollTop: targetOffsetTop}, 250);
    }
    window.addEventListener("pageshow", setAnchorLink);
  }
});
